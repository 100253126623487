<template>
  <div class="content">
    <router-view />
  </div>
</template>

<script lang="ts">

export default {
}
</script>

<style>
.content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}
</style>
