import { createApp, App as VueApp } from 'vue'
import App from './App.vue'
import router from './router'

import '@/assets/main.css'

let app: VueApp | undefined

if (!app) {
  app = createApp(App)
  app.use(router).mount('#app')
}
